import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//获取待开票列表
export const agentList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/AgentList`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取待办运单列表 
//0表示货主发起查询，1表示服务商发起查询
//0未支付 1已支付
//0任务开启 1同意开票 2拒绝开票 3结束 4服务商已开票
export const pendProgress = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/PendProgress`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取 货主->草稿箱列表
export const draftBill = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/ConsInfo/DraftBill?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取 货主->核对发票列表
export const ConsChecking = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/ConsChecking`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取 服务商->上传发票的待办列表
export const pendingList = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/Pending`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取正在申请列表
export const disPlayRetOrders = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/ConsInfo/DisPlayRetOrders`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//搜索
export const searchWaybill = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/ConsInfo/DisPlayRetOrders`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//服务商同意货主放弃付款
export const giveUpInvoice = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/GiveUpInvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//货主同意服务商放弃开票
export const consignAgreeGiveUpInvoice9 = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/ConsInfo/ConsignAgreeGiveUpInvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

export const consignAgreeGiveUpInvoice3 = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Trust/TrustConsignAgreeGiveUpInvoice`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

export const getAgentBySupplier = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/AgentWaybill/GetAgentBySupplier`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

export const DownAgentBySupplier = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/AgentWaybill/DownAgentBySupplier`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 服务商 付款申请 税务表格下载
export const DownSupplierPayWaybills = (params,type) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/DownSupplierPayWaybills`, params,type).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 服务商 上传发票 税务表格下载
export const DownSupplierInvoiceWaybills = (params,type) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/DownSupplierInvoiceWaybills`, params,type).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 结算单退款
export const Refund = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/WayBillPlus/Refund`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

// 结算单退单退款
export const RefundWaybill = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/v2/InvoiceTask/RefundWaybill`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取 平台->核对发票列表
export const PlatAuditInvoicePage = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Provider/PlatAuditInvoicePage`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}